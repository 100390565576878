import React from "react"
import ImageMeta from "../components/ImageMeta"
import { graphql, Link } from "gatsby"
import {
  sortArchive,
  getFeaturedProceduresAndOtherProceduresObject,
  getPostObj
} from "../utils/utils"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import {
  ProcedureQuery,
  SpanishProcedureQuery
} from "../fragments/ProcedureQuery"
import PropTypes from "prop-types"
import SEO from "../components/seo"

var classNames = require("classnames")

function createMarkup(html) {
  return { __html: html }
}
function ProcedureHeading(html) {
  return <h4 dangerouslySetInnerHTML={createMarkup(html)}></h4>
}
function ProcedureParagraph(html, className) {
  return (
    <p className={className} dangerouslySetInnerHTML={createMarkup(html)}></p>
  )
}

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

function ProceduresRow(props) {
  let procedures = props.procedures
  let itemNumber = props.itemNumber
  let rowClass = props.rowClass ? props.rowClass : ""

  if (procedures && itemNumber === 2) {
    return (
      <div className={`columns procedure-row ${rowClass}`}>
        <div className="column is-2"></div>
        <div className="column">
          {!procedures[0].generatePage ? (
            <div className="cls__procedure-query">
              <ImageMeta
                cloudName="nuvolum"
                publicId={procedures[0].thumbnailPublicId}
                responsive
                crop="scale"
                width="auto"></ImageMeta>
            </div>
          ) : (
            <Link to={"/" + procedures[0].title}>
              <div className="cls__procedure-query">
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={procedures[0].thumbnailPublicId}
                  responsive
                  crop="scale"
                  width="auto"></ImageMeta>
              </div>
            </Link>
          )}
          {props.language === "es" && !procedures[0].generatePage ? (
            ProcedureHeading(procedures[0].name)
          ) : (
            <Link className="hover-link" to={"/" + procedures[0].title}>
              {ProcedureHeading(procedures[0].name)}
            </Link>
          )}

          {ProcedureParagraph(procedures[0].blurb, "")}
        </div>
        <div className="column is-2"></div>
        <div className="column">
          {procedures[1] && (
            <>
              {!procedures[1].generatePage ? (
                <div className="cls__procedure-query">
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={procedures[1].thumbnailPublicId}
                    responsive
                    crop="scale"
                    width="auto"></ImageMeta>
                </div>
              ) : (
                <Link to={"/" + procedures[1].title}>
                  <div className="cls__procedure-query">
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={procedures[1].thumbnailPublicId}
                      responsive
                      crop="scale"
                      width="auto"></ImageMeta>
                  </div>
                </Link>
              )}
              {props.language === "es" && !procedures[1].generatePage ? (
                ProcedureHeading(procedures[1].name)
              ) : (
                <Link className="hover-link" to={"/" + procedures[1].title}>
                  {ProcedureHeading(procedures[1].name)}
                </Link>
              )}
              {ProcedureParagraph(procedures[1].blurb, "")}
            </>
          )}
        </div>
        <div className="column is-2"></div>
      </div>
    )
  } else {
    return
  }
}

function AdditionalProcedures(props) {
  let procedures = props.procedures
  sortArchive(procedures)
  let leftHalf = []
  let rightHalf = []

  for (let i = 0; i < procedures.length; i++) {
    if (i % 2 === 0) {
      leftHalf.push(procedures[i])
    } else if (i % 2 === 1) {
      rightHalf.push(procedures[i])
    }
  }
  return (
    <div className="additional-procedures">
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column">
          <h3 style={{ marginBottom: "1.125rem" }}>
            {props.additionalProceduresHeading}
          </h3>
        </div>
      </div>
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column">
          <div className="columns">
            <div className="column additional-procedure-column">
              {leftHalf.map(procedure => {
                let heading

                if (props.language === "es" && !procedure.generatePage) {
                  heading = ProcedureHeading(procedure.name)
                } else {
                  heading = (
                    <Link className="hover-link" to={"/" + procedure.title}>
                      {ProcedureHeading(procedure.name)}
                    </Link>
                  )
                }

                return (
                  <>
                    <div className="additional-procedure-item">
                      {heading}
                      {ProcedureParagraph(procedure.blurb, "")}
                    </div>
                  </>
                )
              })}
            </div>
            <div className="column is-2"></div>
            <div className="column additional-procedure-column">
              {rightHalf.map(procedure => {
                let heading

                if (props.language === "es" && !procedure.generatePage) {
                  heading = ProcedureHeading(procedure.name)
                } else {
                  heading = (
                    <Link className="hover-link" to={"/" + procedure.title}>
                      {ProcedureHeading(procedure.name)}
                    </Link>
                  )
                }

                return (
                  <>
                    <div className="additional-procedure-item">
                      {heading}
                      {ProcedureParagraph(procedure.blurb, "")}
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </div>
        <div className="column is-2"></div>
      </div>
    </div>
  )
}

AdditionalProcedures.propTypes = {
  procedures: PropTypes.array
}

const ProceduresQueryPage = ({ pageContext, location, data }) => {
  var post
  var language
  let postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  post = postObj.post
  language = postObj.language

  let allProcedures

  switch (language) {
    case "en":
      allProcedures = data.allProceduresJson.nodes
      break
    case "es":
      allProcedures = data.allSpanishProceduresJson.nodes
      break
    default:
      allProcedures = data.allProceduresJson.nodes
  }

  var featuredProcedures = []

  function sortFeaturedProceduresAndGetAll(procedures) {
    var featuredAndOtherProcedures =
      getFeaturedProceduresAndOtherProceduresObject(procedures)

    let featuredProcedures = featuredAndOtherProcedures.featuredProcedures
    let otherProcedures = featuredAndOtherProcedures.otherProcedures

    sortArchive(featuredProcedures)
    allProcedures = featuredProcedures.concat(otherProcedures)
    return allProcedures
  }

  allProcedures = sortFeaturedProceduresAndGetAll(allProcedures)

  var additionalProcedures = []
  var procedurePair = []

  for (let i = 0; i < allProcedures.length; i++) {
    let procedure = allProcedures[i]

    if (procedure.procedureLevel) {
      if (
        procedure.procedureLevel === "featured" &&
        procedurePair.length <= 1
      ) {
        procedurePair.push(procedure)
        if (procedurePair.length === 2) {
          featuredProcedures.push(procedurePair)
          procedurePair = []
        }
      } else {
        additionalProcedures.push(procedure)
      }
    }
  }

  if (procedurePair.length === 1) {
    additionalProcedures.push(procedurePair[0])
  }

  var featuredProceduresRows = featuredProcedures.map((row, i) => {
    if (i === featuredProcedures.length - 1) {
      var rowClass = "last"
    }
    return (
      <ProceduresRow
        language={language}
        key={i}
        procedures={row}
        rowClass={rowClass}
        itemNumber={row.length}
      />
    )
  })

  var queryClasses = classNames("joshua-tree-content procedure-query", {
    es: language === "es"
  })

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          schemaPage={post.schemaPage ? post.schemaPage : null}
          pathname={location.pathname}
        />
        <div className={queryClasses}>
          <div className="columns top-section text-section white-back">
            <div className="column">
              <div className="columns is-mobile">
                <div className="column is-5"></div>
                <div className="column">
                  <div>
                    <h1>{post.heading}</h1>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-4"></div>
                <div className="column is-16">
                  <p
                    dangerouslySetInnerHTML={createHtml(
                      converter.makeHtml(post.topBlurb)
                    )}></p>
                </div>
                <div className="column is-4"></div>
              </div>
            </div>
          </div>
          <div className="body-section additional-procedures-section">
            {featuredProceduresRows}
            {/* <div className="columns parallax-procedure">
              <div className="column is-full-width">
                <ImageMeta
                  cloudName="nuvolum"
                  publicId="FOMS/wisdom-teeth-banner-background.jpg"
                  
                  width="auto"
                >
                  
                </ImageMeta>
              </div>
            </div> */}
            <AdditionalProcedures
              language={language}
              procedures={additionalProcedures}
              additionalProceduresHeading={post.additionalProceduresHeading}
            />
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

ProceduresQueryPage.propTypes = {
  data: PropTypes.shape({
    allProceduresJson: PropTypes.shape({
      nodes: PropTypes.shape({
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        blurb: PropTypes.string.isRequired,
        procedureLevel: PropTypes.string.isRequired,
        length: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
}

export const pageQuery = graphql`
  query proceduresQueryPageEs($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        topBlurb
        additionalProceduresHeading
        schemaPage
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        topBlurb
        additionalProceduresHeading
        schemaPage
      }
    }
    allProceduresJson {
      nodes {
        ...ProcedureQuery
      }
    }
    allSpanishProceduresJson {
      nodes {
        ...SpanishProcedureQuery
      }
    }
  }
`

export default ProceduresQueryPage
